<template>
    <div class="sign-box">
        <div class="sign-wrapper">
            <div class="logo">
                <img src="./images/logo.svg" alt="">
            </div>
            <h1>Sign Up</h1>
            <ValidationObserver ref="signUp">
                <b-form slot-scope="{ validate }" @submit.prevent="handleSubmit">
                    <ValidationProvider rules="required|email" name="Email" tag="div">
                        <div class="form-group" slot-scope="{ valid, errors }">
                            <b-form-input
                                v-model="email"
                                :state="errors[0] ? false : (valid ? true : null)"
                                placeholder="Email"
                            >
                            </b-form-input>
                            <b-form-invalid-feedback>
                                {{ errors[0] }}
                            </b-form-invalid-feedback>
                        </div>
                    </ValidationProvider>
                    <div class="text-center">
                        <b-button type="submit" variant="primary submit">Sign Up</b-button>
                    </div>
                </b-form>
            </ValidationObserver>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapMutations } from 'vuex'
    import { errorsAlertApi } from '@/helpers/errorsAlertApi'

    export default {
        name: "signup",
        methods: {
            ...mapActions([
                'signUp'
            ]),
            ...mapMutations([
                'setUserEmail'
            ]),
            async handleSubmit() {
                const isValid = await this.$refs.signUp.validate();
                if(isValid){
                    const data = {
                        email: this.email
                    };
                    this.setUserEmail(this.email);
                    this.signUp(data).then(() => {
                        this.$router.push({name: 'signin', replace: true});
                    }).catch(error => {
                        errorsAlertApi(error);
                    })
                }
            }
        },
        data(){
            return {
                email: null
            }
        }
    }
</script>

<style scoped>

</style>